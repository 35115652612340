/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~ngx-toastr/toastr';
@import './theme.scss';


$primary: #6A6A6A;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

h1,
h2,
h3 {
  margin: 0px;
}

ul {
  margin: 0px;
}

.select-all-label {
  padding-right: 250px;
}

.diffCheck {
  display: block;
  padding: 5px 15px;

  &:hover {
    background: rgba(0, 0, 0, .04);
  }
}

.alert-message,
#alert-message {
  position: fixed;
  width: 90%;
  max-width: 500px;
  z-index: 10000;
  top: 20px;
  left: 50%;
  text-align: center;
  font-size: 14px;
  padding: 20px;
  display: none;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow-wrap: break-word;
}

#alert-message {
  display: none;
}

.alert-success {
  display: block !important;
  background: #4688F1;
  color: #fff;
  border-radius: 2px;
}

.alert-warning {
  display: block !important;
  background: #fa9d2f;
  color: #fff;
  border-radius: 2px;
}

.alert-error {
  display: block !important;
  background: #E15456;
  color: #fff;
  z-index: 99;
}
body{
    font-family: 'Roboto', sans-serif;
    background:#fff;
    font-size: 13px;
}
ul{
  padding:0px;
  list-style-type:none;
}
 .clearfix{
     &::after{
            content: '';
            clear: both;
            width: 100%;
            float: none;
            height: 0;
            margin: 0;
            padding: 0;
            display: block;
        }
   }
 p{
     margin:0px;
 }
    .po-select-field{
        padding-right:20px;
         .mat-form-field-wrapper{
            padding-bottom:0px;
            .mat-form-field-underline{
                display:none;
             }
          .mat-select-arrow{
             background-image: url('assets/expand-arrow.png');
             background-repeat:no-repeat;
             background-size: cover;
             height:15px;
             width:20px;
             border-top:0px;
            font-size:12px;
          }
          .mat-form-field-infix{
            width: 130px;
          }
        }
      }
      .po-select-field-small{
        padding-right:20px;
         .mat-form-field-wrapper{
            padding-bottom:0px;
            .mat-form-field-underline{
                display:none;
             }
          .mat-select-arrow{
             background-image: url('assets/expand-arrow.png');
             background-repeat:no-repeat;
             background-size: cover;
             height:15px;
             width:20px;
             border-top:0px;
            font-size:12px;
            background-position: top;
          }
          .mat-form-field-infix{
            width: 80px;
          }
        }
      }
     .btn{
       background:none;
       border:none;
       box-shadow:none;
       color: white;
       font-size: 12px;
       font-weight: 600;
       border-radius:2px;
       height:30px;
       line-height:30px;
       cursor:pointer;
       &:focus{
         outline:none;
       }
    }

   //form-fields
  ::-webkit-input-placeholder {
    color:#ddd;
    font-size: 11px;
  }
  ::-moz-placeholder {
    color:#ddd;
    font-size: 11px;
  }
  :-ms-input-placeholder {
    color:#ddd;
    font-size: 11px;
  }
  :-moz-placeholder {
    color:#ddd;
   font-size: 11px;
  }
  .action_dropdown{
    border-radius: 4px;
    box-shadow: 0 3px 6px #aaa;
    width: 200px;
    z-index: 1;
    background: #fff;
  }
body {
  font-family: 'Roboto', sans-serif;
  background: #fff;
  font-size: 13px;
}

ul {
  padding: 0px;
  list-style-type: none;
}

.clearfix {
  &::after {
    content: '';
    clear: both;
    width: 100%;
    float: none;
    height: 0;
    margin: 0;
    padding: 0;
    display: block;
  }
}

p {
  margin: 0px;
}

.po-select-field {
  padding-right: 20px;

  .mat-form-field-wrapper {
    padding-bottom: 0px;

    .mat-form-field-underline {
      display: none;
    }

    .mat-select-arrow {
      background-image: url('assets/expand-arrow.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 15px;
      width: 20px;
      border-top: 0px;
      font-size: 12px;
    }

    .mat-form-field-infix {
      width: 130px;
    }
  }
}

.po-select-field-small {
  padding-right: 20px;

  .mat-form-field-wrapper {
    padding-bottom: 0px;

    .mat-form-field-underline {
      display: none;
    }

    .mat-select-arrow {
      background-image: url('assets/expand-arrow.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 15px;
      width: 20px;
      border-top: 0px;
      font-size: 12px;
    }

    .mat-form-field-infix {
      width: 90px;
    }
  }
}
// new structure
.filterField{
  padding-right:20px;
   .mat-form-field-wrapper{
      padding-bottom:0px;
      .mat-form-field-underline{
          display:none;
       }
    .mat-select-arrow{
       background-image: url('assets/expand-arrow.png');
       background-repeat:no-repeat;
       background-size: cover;
       height:15px;
       width:20px;
       border-top:0px;
      font-size:12px;
      background-position: top;
    }
    .mat-form-field-infix{
      width: 90px;
    }
  }
}

.po-select-field-cat{
  //padding-right:20px;
   .mat-form-field-wrapper{
      padding-bottom:0px;
      .mat-form-field-underline{
          display:none;
       }
    .mat-select-arrow{
       background-image: url('assets/expand-arrow.png');
       background-repeat:no-repeat;
       background-size: cover;
       height:15px;
       width:20px;
       border-top:0px;
      font-size:12px;
    }
    .mat-form-field-infix{
      width: 280px;
    }
  }
}

.btn {
  background: none;
  border: none;
  box-shadow: none;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

//form-fields
::-webkit-input-placeholder {
  color: #ddd;
  font-size: 11px;
}

::-moz-placeholder {
  color: #ddd;
  font-size: 11px;
}

:-ms-input-placeholder {
  color: #ddd;
  font-size: 11px;
}

:-moz-placeholder {
  color: #ddd;
  font-size: 11px;
}

.action_dropdown {
  border-radius: 4px;
  box-shadow: 0 3px 6px #aaa;
  width: 200px;
  z-index: 1;
  background: #fff;

  ul {
    li {
      button {
        font-size: 12px;
        display: block;
        padding: 10px;
        color: #212121;
        border: 0;
        width: 100%;
        text-align: left;
        line-height: initial;
        background: #fff;

        i {
          font-size: 14px;
          margin-right: 5px;
          top: 0px;
          color: #6F6F6F;
        }
      }
    }
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.heading-h3 {
  color: #363636;
  font-weight: bold;
  line-height: 30px;
  font-size: 14px;
}

.small-txt {
  font-size: 11px;
  color: #ddd;
}

//small size
.small-size {
  mat-checkbox {
    .mat-checkbox-inner-container {
      width: 13px;
      height: 13px;
      margin-right: 4px;
    }

    &.white {
      .mat-checkbox-frame {
        border-color: white;
      }
    }
  }

  mat-radio-group,
  mat-radio-button {

    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 13px;
      height: 13px;
    }

    .mat-radio-checked .mat-radio-inner-circle {
      transform: scale(.4);
    }
  }
}

//global search
.search-global {
  position: relative;

  input {
    height: 35px;
    border: 0;
    border: 0.5px solid #E2E2E2;
    width: calc(350px - 30px);
    padding: 0 35px 0 10px;
    background: #fff;
    border-radius: 3px;
  }

  input+i {
    position: absolute;
    right: 1px;
    top: 0px;
    bottom: 1px;
    color: #fff;
    background: #6A6A6A;
    cursor: pointer;
    font-size: 20px;
    width: 30px;
    text-align: center;
    line-height: 35px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
}

i.view-arrow {
  background: #EFEFEF;
  color: #000;
  font-size: 26px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
  padding: 0px 4px;
  margin: 0px 15px;
  cursor: pointer;
}

.black {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #464646
  }

  .mat-checkbox-frame {
    border: 1.5px solid #464646
  }

  .mat-checkbox-label {
    color: #464646;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #464646;
    }
  }
}

.sheet-group-repeatRfq {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #e67d40
  }

  .mat-checkbox-frame {
    border: 1.5px solid #e67d40
  }

  .mat-checkbox-label {
    color: #e67d40;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #e67d40;
    }
  }
}

.blue {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #005EB8;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #005EB8;
  }

  .mat-checkbox-label {
    color: #005EB8;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #005EB8;
    }
  }
}

.dim-green {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #00B2A9;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #00B2A9;
  }

  .mat-checkbox-label {
    color: #00B2A9;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #00B2A9;
    }
  }
}

.dark-green {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #008264;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #008264;
  }

  .mat-checkbox-label {
    color: #008264;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #008264;
    }
  }
}

.light-green {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #78BE20;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #78BE20;
  }

  .mat-checkbox-label {
    color: #78BE20;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #78BE20;
    }
  }
}

.sky-blue {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #22B4FF;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #22B4FF;
  }

  .mat-checkbox-label {
    color: #22B4FF;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #22B4FF;
    }
  }
}

.white-bg {
  background: white;
  height: 100vh;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.line-80 {
  .mat-form-field-underline {
    width: 85%;
  }
}

.mat-dialog-container {
  padding: 0px !important;
}

.checkTd {
  text-align: center;

  .mat-checkbox {
    display: block;

    label {
      .mat-checkbox-inner-container {
        margin-right: 0px !important;
      }
    }
  }
}

.ngx-pagination {
  text-align: right;
  margin-top: 0px;
  margin-bottom: 0px !important;
  background: #ddd;

  li {
    padding: 8px 5px;
  }
}

.mar-t-5 {
  margin-top: 5px;
}

.cdk-virtual-scroll-content-wrapper {
  position: relative !important;
  width: 100%;
  float: inherit;
  max-width: 100%;
  float: inherit;
  display: -webkit-box;
  display: flex;
  // height: 100vh;
  // height: calc(100vh - -990px);
  height: calc(100vh - -790px);
}

.cdk-virtual-scroll-spacer {
  top: -41px !important;
}

.pad-t-5 {
  padding-top: 5px !important;
}

.filter_dropdown {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 25px #999;
  z-index: 1;
  position: relative;
  padding-bottom: 42px;

  .box {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;

    p {
      font-size: 14px;
      color: #777;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    .check-label {
      display: block;
      margin-bottom: 10px;
    }
  }

  .mat-checkbox {
    display: block;

    .mat-checkbox-layout {
      margin-bottom: 0;
      font-weight: normal;
      display: block;
    }

    .mat-checkbox-inner-container {
      width: 13px;
      height: 13px;
      margin-top: 0;
    }
  }

  .bottom_bar {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    text-align: left;
    background: #f3f3f3;

    button {
      text-transform: uppercase;
      padding: 3px 0;
      font-weight: bold;
      margin-right: 25px !important;
    }
  }

  .grey_search {
    padding: 0 8px;
    margin-bottom: 10px;

    mat-icon {
      cursor: pointer;
    }
  }
}

.grey_search {
  position: relative;

  input {
    height: 35px;
    border: 0;
    background: #F0F0F0;
    box-shadow: 0 1px 2px #aaa;
    padding: 0 10px;
    width: 100%;
    padding: 0px 35px 0px 15px;
  }

  .mat-icon {
    position: absolute;
    right: 15px;
    top: 7px;
    font-size: 20px;
    color: #777;
  }
}

.filter_dropdown {
  .mat-menu-content {
    width: 270px;


  }
}

/*Custom checkbox text*/

.custom-checkbox-style .mat-option-text {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  line-height: 16px;
}

.md-drppicker {
  width: 515px !important;
}

.cal-li {
  label {
    font-weight: 400 !important;
  }
}


// overflow-y

.overflow-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;
}

// scrollbar

.scrollWrap {
  width: auto;
  overflow: scroll;
}

.scrollWrap::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;

}

.Inner {
  width: 600px;
}

.Inner::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;

}

.side-bar {
  .logout-btn {
    display: block;
    height: auto;
    padding: 0;
    font-size: 10px;
    font-weight: 500;
    background: transparent;
    border: 0px;
  }

  #profile {

    img,
    .user-detail {
      display: none;
    }
  }

  &.active {
    .logout-btn {
      display: none;
      height: 0;
    }

    #profile {

      img,
      .user-detail {
        display: block;
      }
    }
  }
}


.right-bar {
  .main-area {
    height: 100vh !important;
    padding-top: 45px;
  }
}

.catalogitemsheet {
  cdk-virtual-scroll-viewport {
    height: calc(100vh - 200px) !important;
  }
}

.supplieritemsheet {
  cdk-virtual-scroll-viewport {
    height: calc(100vh - 225px) !important;
  }
}

.side-bar {
  float: left;
  width: 50px;
  // position: fixed;
  white-space: nowrap;
  overflow: hidden;
  // left: 0;
  // bottom: 0;
  // height: 100%;
  background: #F8F8F8;
  z-index: 56;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: all 0.1s ease-in;

  &.active {
    // width: 200px;
    width: 100%;
    z-index: 999;
  }
}

// Bulk Upload 

.nav_tab_bar {
  padding-top: 7px;
  background-color: white;

  nav .mat-tab-link {
    color: #363636;
    font-size: 13px;
  }
}

.mat-tab-label-active {
  color: #5ac6fb !important;
  font-weight: 600 !important;
}

.mat-ink-bar {
  background-color: #25b3fb !important;
}

.BO-align-flex {
  display: flex;
  height: 60px;
  background-color: white;

  div {
    padding: 19px 0px 0px 16px;
  }
}

.bo-heading {
  font-weight: 600;
  font-size: 14px;
}

.align-right {
  margin-left: 60% !important;
  border-right: 1px solid #dbdbdb;
  color: #25b3fb;
  font-size: 14px;
  font-weight: 500;
  padding-right: 14px !important;
}

.upload-file-css {
  color: #25b3fb;
  font-size: 14px;
  font-weight: 500;
}

.download-sheet {
  margin: 10px 0px 10px 10px;
  height: 36px;
  width: 167px;
  padding: 7px 0px 5px 5px;
  background-color: #d0d0d0;
  color: white;
  border-radius: 2px;
  font-weight: 550;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.mat-table thead {
  height: 38px !important;
}

.mat-checkbox {
  .mat-checkbox-layout {
    margin-bottom: 0;
  }
}

.small-text-parameter-heading {
  font-size: 10px;
  color: #656262;
}

.dark-blue-green {
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #27778a;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #27778a;
  }

  .mat-checkbox-label {
    color: #27778a;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.7px
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #27778a;
    }
  }
}

//   .cdk-overlay-container {
//   z-index: 9999 !important; 
// }

app-items .mat-form-field-appearance-legacy {
  padding: 0px !important;
}

.item-tax-dd-width {
  width: 69px;
}

.font-size-11 {
  font-size: 11px;
}

.sp-dialog .mat-dialog-container {
  overflow-y: hidden !important;
}

.mat-option-text.mat-option-text {
  overflow: auto;
  text-overflow: unset;
}

// supplier suggetion dialog css part width
.supplier-suggest-dialog .mat-dialog-container {
  max-width: 98% !important;
  width: 97%;
  height: 95%;
  position: absolute;
  left: 20px;
}

app-rfq-sheet .mat-expansion-panel-content {
  font-size: 12px;
}

app-rfq-sheet .mat-expansion-panel-header {
  font-size: 12px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.blue-btn {
  background-color: #4688F1;
  border: 1px solid #4688F1;
  border-radius: 2px;
  color: #fff;
  height: auto;
  padding: 5px 25px;
  line-height: 20px;

  &:hover {
    color: #fff !important;
  }
}

.d-flex {
  display: flex !important;
}

.mog3_msnsearchtable_sticky {
  border-radius: 4px;
  height: calc(100vh - 308px);
  width: 100%;
  max-width: 100%;
  overflow: auto;


  .mat-table {
    // min-width: 100%;
    width: 100%;
    max-width: 100%;

    &.border-table {
      tbody {
        tr {
          border-bottom: 1px solid #95989a33;

          td {
            border-right: 1px solid #95989a33;
          }
        }
      }
    }

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      border-bottom: none;
    }

    thead {
      height: 38px !important;
      border-radius: 4px;

      .mat-header-cell {
        background: #EAEAEA;
        padding: 0 10px;
        font-size: 12px;
        color: #363636;
        font-weight: 500;

        &.cdk-column-plantname {
          width: 15%;
          max-width: 15%;
        }

        &.cdk-column-creationdate {
          i {
            font-size: 18px;
            display: inline-block;
            vertical-align: middle;
          }

          span {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }

    tbody {
      tr.mat-row {
        border-bottom: 1px solid #eee;
      }

      .mat-cell {
        padding: 10px;
        font-size: 14px;
        color: #363636;

        &.cdk-column-sano {
          span {
            font-weight: 600;
          }
        }

        &.cdk-column-action {
          span {
            font-weight: 500;
          }
        }

        &.cdk-column-more {
          i {
            font-size: 20px;
          }
        }

        &.cdk-column-creationdate {
          font-weight: 600;

          span {
            font-weight: 400;
            display: block;
          }
        }

      }

    }
  }


}

.font-500 {
  font-weight: 500 !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.primary-btn {
  background-color: #4688F1 !important;
  border: 1px solid #4688F1 !important;
  border-radius: 0px;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 11px !important;

  &.disabled {
    background-color: #8db5f4 !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

 .custom-tooltip {
  background-color: #E15456 !important;
  margin-top: 34px !important;
  font-size: 12px !important;
}